import axios, {AxiosResponse} from "axios";
import {
    Account,
    AccountTools,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    LoginRequest,
    LoginResponse
} from "../interfaces/auth_interface";
import {
    API_URL,
    DEVICELOCATION_URL,
    ENABLED_TOOLS,
    EOS_URL,
    HMNTRFC_URL,
    NIBIN_URL,
    NJPOP,
    NJSTAD_URL, NOTIFICATIONS_URL,
    SiteName,
    THREAT_URL
} from "./config.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import {GetStringNameForSite, IsToolEnabled, SITE, TOOLS} from "../interfaces/config.interface";


export async function  LoginAPI(creds:LoginRequest) : Promise<LoginResponse> {
    let res:AxiosResponse<LoginResponse> = await axios.post('/api/Account/Login',creds, {
        baseURL: API_URL,
        withCredentials: false,
    })
    if(res.status<300)
        return res.data
    else
        return {AuthToken: null,LoggedUser:null}
         
}

export async function  AcceptedSystemNotice(creds:any) : Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/SystemNotice', creds)
    return (res.data)

}

export async function  AcceptedAgreement(creds:any) : Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/Agreement', creds)
    return (res.data)
}

export async function  ForgotPasswordAPI(creds:ForgotPasswordRequest) : Promise<ForgotPasswordResponse> {
    let res:AxiosResponse<ForgotPasswordResponse> = await axios.post('/api/Account/ResetPassword',creds, {
        baseURL: API_URL,
        withCredentials: false,
    })
    if(res.status<300)
        return res.data
    else
        return {AuthToken: null,LoggedUser:null}
         
}

export function getUser():Account{
    let user: string | null = localStorage.getItem("user")
    try{
        if(user){
            let usrObj:Account = JSON.parse(atob(user.split('.')[1]))
            return usrObj;
        }
    }
    catch{
        
    }
   
    return { AccountType: null, DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, DeptUnitID: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null};
      
}

export function getDashboardUser():Account{
    let user : string | null = localStorage.getItem("dashboard")
    if(user){
        let usrObj:Account = JSON.parse(atob(user.split('.')[1]))
        return usrObj;
    }
    return { AccountType: null, DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, DeptUnitID: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null};

}

export function getDeviceUser():Account{
    let user : string | null = localStorage.getItem("DLUser")
    if(user){
        let usrObj:Account = JSON.parse(atob(user.split('.')[1]))
        return usrObj;
    }
    return { AccountType: null, DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, DeptUnitID: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null};

}

export function getExpiry():string | null{
    let ex : string | null = localStorage.getItem("expiry")
    return ex
}


export function getToken():string{
    let user : string | null = localStorage.getItem("user")
    if(user){
        return user
    }
    return ""
}

export function getDeviceToken():string{
    let user : string | null = localStorage.getItem("DLUser")
    if(user){
        return user
    }
    return ""
}

export function getDashboardToken():string{
    let dashboard : string | null = localStorage.getItem("dashboard")
    if(dashboard){
        return dashboard
    }
    return ""
}

export async function RefreshUserToken (force:boolean = false){
    try{
        let user = getUser();
        let now =  new Date();
        if(user.exp){
            //On Last API Call within 40 Minutes of Exp get new token
            if ((((user.exp * 1000) - now.getTime())<(2400000) && ((user.exp * 1000) - now.getTime())>0) || force) {
                let res:AxiosResponse<LoginResponse> = await axios.get('/api/Account/RefreshToken', {
                    baseURL: API_URL,
                    withCredentials: false,
                    headers:{
                        AuthToken: getToken()
                    }
                })
                
                if(res.data.AuthToken)
                    localStorage.setItem('user', res.data.AuthToken)
            }
        }
    }catch (e) {
        console.log("Unable to get new authentication token.")
    }
}

export function isUserLoggedIn():boolean{
    let user:Account = getUser();
    return !!(user.exp && user.eOTP && user.EmpID && user.Dept && (user.DeptAccountType !== 'BTAM'));
}

export function logoutUser() {
        localStorage.removeItem("user");
        localStorage.removeItem("expiry");
        localStorage.removeItem("TA");
        localStorage.removeItem("DLUser");
        //localStorage.clear();
}

export function getUserTools():AccountTools[]{
    let user:Account = getUser();
    let tools:AccountTools[] = [];
    
    //tools.push({href: "", name: ""})
    let showArrest:boolean = isUserPermission('Arrest')
    let showLPR: boolean = isUserPermission('LPR')
    if (showArrest && IsToolEnabled(TOOLS.ANKLE, ENABLED_TOOLS))
        tools.push({ href: "/tools/anklebracelet", name: "Ankle Bracelet Track" })
    if(showArrest && IsToolEnabled(TOOLS.ARREST, ENABLED_TOOLS))
        tools.push({ href: "/tools/arrest", name: "Arrest" })
    if (showArrest && IsToolEnabled(TOOLS.ARREST_ANALYSIS, ENABLED_TOOLS))
        tools.push({ href: "/tools/arrest_analysis", name: "Arrest Analysis" })
    if (showArrest && IsToolEnabled(TOOLS.CARJACKING_NOTIFICATION, ENABLED_TOOLS))
        tools.push({ href: "/tools/carjacking_notification", name: "Carjacking Notification" })
    if (isUserPermission('CODIS') && IsToolEnabled(TOOLS.CODIS_NOTIFICATION, ENABLED_TOOLS))
        tools.push({ href: "/tools/codis_notification", name: "CODIS Notification" })
    if (isUserPermission('DeviceID') && IsToolEnabled(TOOLS.DEVICE_LOCATION, ENABLED_TOOLS))
        tools.push({ href: DEVICELOCATION_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Device IDs", type: 'A' })
    if (isUserPermission('DuplicatePerson') && IsToolEnabled(TOOLS.DUPLICATE_PERSON, ENABLED_TOOLS))
        tools.push({ href: "/tools/duplicate_person", name: "Duplicate Person" })
    if(isUserPermission('ECRAWL') && IsToolEnabled(TOOLS.ECRAWL, ENABLED_TOOLS))
        tools.push({ href: "/tools/ecrawl", name: "eCRAWL" })
    if (showArrest && isUserPermission('PIR') && IsToolEnabled(TOOLS.PRELIMINARY_REPORT, ENABLED_TOOLS))
        tools.push({ href: "/tools/preliminary_report", name: "Preliminary Report" })
    if (showArrest && isUserPermission('TSC') && IsToolEnabled(TOOLS.TSC_FORM, ENABLED_TOOLS))
        tools.push({ href: "/tools/terrorist_screening", name: "Terrorist Screening"})
    
    if (isUserPermission('EyeOnScene') && IsToolEnabled(TOOLS.EYE_ON_SCENE, ENABLED_TOOLS))
        tools.push({ href: EOS_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "EyeOnScene", type: 'A' })
    if(showArrest && IsToolEnabled(TOOLS.FREQUENT_OFFENDER, ENABLED_TOOLS))
        tools.push({ href: "/tools/frequent_offender", name: "Frequent Offender" })
    if (showArrest && IsToolEnabled(TOOLS.GVRTF_REFERRAL, ENABLED_TOOLS))
        tools.push({ href: "/tools/GVRTF_Referral", name: "GVRTF Referral" })
    if (isUserPermission('HumanTraffic') && IsToolEnabled(TOOLS.HUMAN_TRAFFICKING, ENABLED_TOOLS))
        tools.push({ href: HMNTRFC_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Human Traffic", type: 'A' })
    if (showArrest && IsToolEnabled(TOOLS.JAIL, ENABLED_TOOLS))
        tools.push({ href: "/tools/jail", name: "Jail" })
    if (showLPR && IsToolEnabled(TOOLS.LPR_COMMON_PLATE, ENABLED_TOOLS))
        tools.push({ href: "/tools/alpr_multiple_occurrence", name: "LPR Common Plate" })
    if (showLPR && IsToolEnabled(TOOLS.LPR_SEARCH, ENABLED_TOOLS))
        tools.push({ href: "/tools/alpr_vin_search", name: "LPR Search" })
    if (isUserPermission('NCIC') && IsToolEnabled(TOOLS.NCIC_SEARCH, ENABLED_TOOLS))
        tools.push({ href: "/ncic?Hash", name: "NCIC Search" })
    /*if (isMainRecordEditor('01_ARREST') && IsToolEnabled(TOOLS.UPDATE_PERSON_MAIN_RECORD, ENABLED_TOOLS))
        tools.push({ href: "/PersonMainRecord?Hash", name: "Update Person MainRecord" })*/
    if (isUserPermission('NIBIN') && IsToolEnabled(TOOLS.NIBIN, ENABLED_TOOLS))
        tools.push({ href: NIBIN_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "NIBIN", type: 'A' })
    
    if (isUserPermission('NOTIFICATION') && IsToolEnabled(TOOLS.NOTIFICATION, ENABLED_TOOLS))
       tools.push({ href: NOTIFICATIONS_URL + "/#/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "EMAIL LIST MANAGEMENT", type: 'A' }) 
    
    if ((showArrest || isUserPermission('SDW')) && IsToolEnabled(TOOLS.SDW, ENABLED_TOOLS)){
        let toolName = (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? "DC Gun Violence" : "NJ POP";
        tools.push({ href: NJPOP + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: toolName, type: 'A' })
    }
    if (isUserPermission('NJSTAD') && IsToolEnabled(TOOLS.NJSTAD, ENABLED_TOOLS))
        tools.push({ href: NJSTAD_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "JSTAD", type: 'A' })
    if ((isUserPermission('NJTrace') || isUserPermission('NJTraceAdd')) && IsToolEnabled(TOOLS.NJTRACE, ENABLED_TOOLS)) {
        let toolName = (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? "DC Trace" : "NJ Trace";
        tools.push({href: "/tools/trace", name: toolName})
    }    
    if (showArrest && IsToolEnabled(TOOLS.OPEN_CASE, ENABLED_TOOLS))
        tools.push({ href: "/tools/open_case_incident", name: `${GetStringNameForSite(SiteName,'NAV_TOOL_OPENCASE')}` })
    if (showArrest && IsToolEnabled(TOOLS.PAROLE, ENABLED_TOOLS))
        tools.push({ href: "/tools/parole", name: "Parole" })
    if(showArrest && IsToolEnabled(TOOLS.PERSON_NEARBY, ENABLED_TOOLS))
        tools.push({ href: "/tools/person_nearby", name: "Person Nearby" })
    //if (showArrest)
    //    tools.push({ href: "/tools/phone_search", name: "Phone Search" })
    if (showArrest && IsToolEnabled(TOOLS.PRISONER_INFO, ENABLED_TOOLS))
        tools.push({ href: "/tools/prisoner_information", name: "Prisoner Information" })
    if (showArrest && IsToolEnabled(TOOLS.PROFESSIONAL_LICENSE, ENABLED_TOOLS))
        tools.push({ href: "/tools/professional_license", name: "Professional License" })
    if (isUserPermission('Arrest') && IsToolEnabled(TOOLS.PROFILE_REPORT, ENABLED_TOOLS))
        tools.push({ href: "/tools/profile_report", name: "Profile Report" })
    if (showArrest && IsToolEnabled(TOOLS.SCOR_SEARCH, ENABLED_TOOLS))
        tools.push({ href: "/tools/scor", name: `${GetStringNameForSite(SiteName,'SCOR')} Search` })
    if (showArrest && IsToolEnabled(TOOLS.SCOR_VARIATION, ENABLED_TOOLS))
        tools.push({ href: "/tools/scor_variation", name: `${GetStringNameForSite(SiteName,'SCOR')} Variation` })  //SCOR Variation / Rising Star / Rising Offender
    if (isUserPermission('Shooting') && IsToolEnabled(TOOLS.SHOOTING_NOTIFICATION, ENABLED_TOOLS))
        tools.push({ href: "/tools/shooting_notification", name: "Shooting Notification" })
    if (isUserPermission('ShotSpotter') && IsToolEnabled(TOOLS.SHOTSPOTTER, ENABLED_TOOLS))
        tools.push({ href: "/tools/shot_spotter", name: "Shot Spotter" })
    if (showArrest && IsToolEnabled(TOOLS.STATUTE_MAPPING, ENABLED_TOOLS))
        tools.push({ href: "/tools/statute_mapping", name: `${GetStringNameForSite(SiteName, 'STATUTE')} Mapping` })
    if (showArrest && IsToolEnabled(TOOLS.STOLEN_VEHICLE, ENABLED_TOOLS))
        tools.push({ href: "/tools/stolen_vehicle", name: "Stolen Vehicle" })
    if (isUserPermission('ThreatAssessment') && IsToolEnabled(TOOLS.THREAT_ASSESSMENT, ENABLED_TOOLS))
        tools.push({ href: THREAT_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Threat Assessment", type: 'A' })
    if(showArrest && IsToolEnabled(TOOLS.TOWED_VEHICLE, ENABLED_TOOLS))
        tools.push({ href: "/tools/towed_vehicle", name: "Towed Vehicle" })
    if (showArrest && IsToolEnabled(TOOLS.VIOLENT_OFFENDER, ENABLED_TOOLS))
        tools.push({ href: "/tools/violent_offender", name: "Violent Offender" })

    if (showArrest && IsToolEnabled(TOOLS.MISSING_PERSON, ENABLED_TOOLS))
        tools.push({ href: "/tools/missing_person", name: "Missing Person" })
    if (showArrest && IsToolEnabled(TOOLS.FIELD_CONTACT, ENABLED_TOOLS))
        tools.push({ href: "/tools/field_contact", name: "Field Contact" })
    if (showArrest && IsToolEnabled(TOOLS.SUPPLEMENTAL_REPORT, ENABLED_TOOLS))
        tools.push({ href: "/tools/supplemental_report", name: "Supplemental" })
    if (showArrest && IsToolEnabled(TOOLS.TRAFFIC_CRASH, ENABLED_TOOLS))
        tools.push({ href: "/tools/traffic_crash", name: "Traffic Crash" })
    
    return tools.sort((a:any,b:any) => a.name.localeCompare(b.name))
}

export function getAuditLog(): Account {
    let auditlog: string | null = localStorage.getItem("AuditLog")
    if (auditlog) {
        let auditlogObj: Account = JSON.parse(atob(auditlog.split('.')[1]))
        return auditlogObj;
    }
    return { AccountType: null, DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, DeptUnitID: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null };

}

export function getUserProfile() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let profilePage:AccountTools[] = [];

    //User Profile
    profilePage.push({ href: "/users", name: "User Profile" })
    
    if(user && user.TokenType != "SAML")
        profilePage.push({ href: "/change_password", name: "Change Password" })
    
    //Audit Log
    if(isUserPermission('SearchHistory'))
    profilePage.push({ href: "/SearchHistory", name: "Search History" })

    if (isUserPermission('Department')) {
        profilePage.push({ href: "/departments", name: "Departments" })
    }
    if (isUserPermission('DeptUnitLevel'))
        profilePage.push({ href: "/deptUnit", name: "Dept Unit Level" })
    
    if(isUserPermission('NJTrace') && IsToolEnabled(TOOLS.NJTRACE, ENABLED_TOOLS))
        profilePage.push({ href: "/NJTraceTracking", name: "NJTrace Tracking" })

    return profilePage
}

export function getDashboards() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let dashboardPage:AccountTools[] = [];
    
    if(isUserPermission('DashBoard_GVRTF')) {
            dashboardPage.push({href: NJPOP + "/auth?UserName=" + user.EmpID + "&vGRAILDash=Y&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: "GVRTF DASHBOARD", type: 'A'})
    }
    if (isUserPermission('DashBoard_NIBIN')) {
        dashboardPage.push({ href: NJPOP + "/auth?UserName=" + user.EmpID + "&vNIBINDash=Y&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: "CRIME GUN DASHBOARD", type: 'A' })
    }
    if (isUserPermission('DashBoard_POP')) {
        dashboardPage.push({ href: "/dashboard", name: `${GetStringNameForSite(SiteName, 'NJPOP').toUpperCase()} DASHBOARD`, type: 'A' })
    }
    if (isUserPermission('DashBoard_STOLEN')) {
        dashboardPage.push({ href: "/dashboard/StolenVehicle", name: "STOLEN VEHICLE DASHBOARD", type: 'A' })
    }
    if (isUserPermission('DashBoard_TRACE')) {
        dashboardPage.push({ href: "/dashboard/eTrace", name: "E-TRACE DASHBOARD", type: 'A' })
    }
    if (isUserPermission('DashBoard_PORT')) {
        dashboardPage.push({ href: "/dashboard/NJPort", name: "PORT Hiring(THEIC) DASHBOARD", type: 'A' })
    }
    if (isUserPermission('DashBoard_PORT')) {
        dashboardPage.push({ href: "/dashboard/INVESTIGATION", name: "PORT B&L Dashboard", type: 'A' })
    }   

    return dashboardPage.sort((a:any,b:any) => a.name.localeCompare(b.name))
}

export function getSupportTabs() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let supportPage:AccountTools[] = [];

    supportPage.push({ href: "https://gtbm.com/training-request-form/", name: "Training Request", type:"A" })
    supportPage.push({ href: "/support/Training", name: "Training Videos" })
    supportPage.push({ href: "/support/FAQ", name: "FAQs" })
    /*supportPage.push({ href: "/support/UserManual", name: "User Manual", type: "A"})*/
    supportPage.push({ href: "https://gtbm.com/contact-us", name: "Contact Us", type: "A" })


    return supportPage
}

export function authHeader() {
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
        user = JSON.parse(userStr);

    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

export function isUserPermission(type: any, dept: any = null) {
    let user: Account = getUser()
    let lclStorageName;
    if (type === 'ThreatAssessment')
        lclStorageName = 'TA'
    else if (type === 'NJSTAD')
        lclStorageName = 'NJSTAD'
    else
        lclStorageName = 'NONE'

    if (user?.Permissions) {
        let permissions = JSON.parse(user?.Permissions);
        if (type === 'ThreatAssessment' || type === 'NJSTAD') {
            let lclStg = localStorage?.getItem(lclStorageName);            
            if (lclStg)
                return permissions.find((permission: { Permission: any; }) => permission.Permission === type)?.HasAccess && lclStg === 'true';
            else
                return false
        }  
        else if (type === 'UserProfileEdit') {
            return permissions.find((permission: { Permission: any; }) => permission.Permission === type)?.HasAccess || (user.Dept === dept)
        }
        else
            return permissions.find((permission: { Permission: any; }) => permission.Permission === type)?.HasAccess;
    }
    else
        return false;
}

export function canEditPOI(poi: any) {
    let user: Account = getUser()

    if (user?.Permissions) {
        let permissions = JSON.parse(user?.Permissions);
        let hasAccess = permissions.find((permission: { Permission: any; }) => permission.Permission === "POIEdit")?.HasAccess;

        if (!hasAccess || poi?.Deleted) {
            return false;
        }
        else {
            if (user.EmpID?.toUpperCase() === poi.EmpID?.toUpperCase()) {
                return true;
            }
            else if (poi.AuthorizedBy?.toUpperCase() === user.EmpID?.toUpperCase()) {
                return true;
            }
            else if (poi.DeptCode?.toUpperCase() === user.Dept?.toUpperCase() && hasAccess) {
                // Managers and Supervisors, Manager, and SuperAdmin can edit/delete others poi
                return true;
            }
            else
                return false;
        }
    }
    else
        return false;
}

export function isMainRecordEditor(TableType?: string | null) {
    let user: Account = getUser()
    if (user?.Permissions) {
        let permissions = JSON.parse(user?.Permissions);
        let hasAccess = permissions.find((permission: { Permission: any; }) => permission.Permission === "MainRecordEdit")?.HasAccess;

        //''01_ARREST'' 		TableType
        //''02_INFOCOP'' 		TableType   -- Don't have write access
        //''03_NJPOP'' 		    TableType
        //''04_CODIS'' 		    TableType
        //''05_CADRMS'' 		TableType
        //''06_ETICKET'' 		TableType   -- Don't have write access
        //''07_PARKMOBILE'' 	TableType   -- Not Applicable
        //''08_ALPR'' 		    TableType   -- Not Applicable
        //''09_ETRACE'' 		TableType
        //''10_NJTRACE'' 		TableType
        //''11_NIBIN'' 		    TableType   -- Can't do since it is court doc (refer BAC-596)
        //''12_PRISON'' 		TableType
        //''13_PAROLE'' 		TableType
        //''14_VIOLENT'' 		TableType
        //''15_TOWING'' 		TableType   -- Not Applicable
        //''16_PROFILE'' 		TableType
        //--''17_REFERRAL'' 	TableType   -- Not Implemented in LOTTO.bt_getPersonAddressPlateDetails
        //--''18_HUMANTRAFFIC'' TableType   -- Not Implemented in LOTTO.bt_getPersonAddressPlateDetails
        //--''19_PROFESSION'' 	TableType   -- Not Implemented in LOTTO.bt_getPersonAddressPlateDetails
        return (TableType === '01_ARREST' || TableType === '03_NJPOP' || TableType === '04_CODIS' || TableType === '05_CADRMS' || TableType === '09_ETRACE' || TableType === '10_NJTRACE' || TableType === '12_PRISON' || TableType === '13_PAROLE' || TableType === '14_VIOLENT' || TableType === '16_PROFILE' || TableType=='20_M43P')
            && hasAccess;
    }  //only NJSP deptunit G000,G210,G220,G230 has access to edit the record
    else
        return false;
}

export function getNJPort() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let logPage: AccountTools[] = [];
    logPage.push({ href: '/njport/analysts', name: "Analysts Request", type: 'A' })
    logPage.push({ href: "/njport/renewal", name: "Applicant Renewal", type: 'A' })
    /*logPage.push({ href: '/njport/arrest', name: "Arrest Investigation", type: 'A' })  */
    logPage.push({ href: "/njport/investigation", name: "Investigation", type: 'A' })
    logPage.push({ href: '/njport/money', name: "Money Orders", type: 'A' })
    logPage.push({ href: '/njport/pdohire', name: "PDO Hire", type: 'A' })
    logPage.push({ href: '/njport/shipentry', name: "Ship Entry", type: 'A' })
    /*logPage.push({ href: "/njport/stevedore", name: "Stevedore", type: 'A' })*/
    /*logPage.push({ href: "/njport/tempcard", name: "Tempcard", type: 'A' })*/
    logPage.push({ href: '/njport/terminalhire', name: "Terminal Hire", type: 'A' })
    logPage.push({ href: '/njport/volunteerhire', name: "Volunteer Hire", type: 'A' })

    return logPage;
}