import { AxiosResponse } from "axios";
import { PersonInfo, getDarInfoResponse } from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { eCRAWL } from "../interfaces/eCRAWL.interface";


export async function GetEcrawl(ID: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/ECrawl/GetECrawl?IDs=' + ID)
    return (res.data)
}

export async function GetCarrierInfo(dotNumber: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/ECrawl/GetCarrierInfo?dotNumber=' + dotNumber)
    return (res.data);

}

export async function SetEcrawl(report: any): Promise<any> {
    let res: AxiosResponse<eCRAWL> = await AuthenticatedAPIReqSplash.post('/api/ECrawl/SetEcrawl', report)
    return (res.data)
}