import React, { useState } from "react";
//import ArrestSearch from "../../components/tools/codis/_codisSearch";
import SearchResults from "../../components/tools/shooting/_searchResultsShooting";
import ShootingSearch from "../../components/tools/shooting/_shootingSearch";
import { ShootingInfo } from "../../interfaces/ShootingInfo.interface";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";

function ShootingNotification() {
    let [shootings, setShootings] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [clear, setClear] = useState<number>(0)
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "6",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": false,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept":  [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setShootings([]) 
        setShootings(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    
    
    const HandleSelectedClear = (tClear:any) => {
        setClear(tClear)
    }
    
    return (
        <div id="ToolsShooting">
            {isUserPermission('Shooting')?
                <>
                    <ShootingSearch SubmitSearch={SubmitSearch} ClearSelected={HandleSelectedClear}/> 
                    <SearchResults ShootingInfo={shootings} SubmitSearch={SubmitSearch} SelectionClear={clear}/>
                </>
            :<></>}
        </div>
    );
}

export default ShootingNotification;
