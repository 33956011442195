import React, { forwardRef } from "react";

import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup, Checkbox,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Tooltip
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import {formatInputDate} from "../../../services/formatDate.service";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

interface ECrawlearchProps {
    SubmitSearch: any,
    passedData?: any | null,
    RefreshTrigger: any
}

interface ECrawlSearchRef {
    handleSubmit: any
}

const ECrawlReportSearch = forwardRef<ECrawlSearchRef, ECrawlearchProps>(({ SubmitSearch, RefreshTrigger, passedData = null }, ref) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else{
        prevDate = new Date(toDate.setDate(toDate.getDate() - 1));
        fromday = prevDate //Default - Previous weekend
        //fromday = toDate;
    }
        
    
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", CarrierName: "", DOT: "", DT: "", DL: "", Plate: "", VIN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    
   
    
    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };
    
    
    
    
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const history = useHistory();

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleInitialBuild = async () => {
        await handleStateDropdown()
        let p = { ...params }
        p.systemCall = true
        SubmitSearch(p, dateType, andOrType, dropDownParams)
    }

    const handleStateDropdown = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)
    }

    React.useEffect(() => {
        handleInitialBuild()
    }, [passedData, RefreshTrigger])


    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", CarrierName: "", DOT: "", DL: "", Plate: "" , VIN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
        setDateType("Entry Date")
        setAndOrType("AND")
        setClear(clear + 1)
    }
    
   
    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        try{
            if(dropDownParam && dropDownParam.City)
                dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        }catch (E){}
       
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }
   

    return (
        <>
            <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
                <div className="grid grid-cols-7 gap-x-4 gap-y-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{mr: 2}}
                    >
                        <ToggleButton value="Contact Date" sx={{pr: 2, pl: 2}}>Contact Date</ToggleButton>
                        <ToggleButton value="Entry Date" sx={{pr: 2, pl: 2}}>Entry Date</ToggleButton>
                    </ToggleButtonGroup>
                    <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="From Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.fromDate}
                                   onChange={(e: any) => {
                                       setParams({...params, fromDate: e.target.value})
                                   }}
                        />
                    </div>
                    <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="To Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.toDate}
                                   onChange={(e: any) => {
                                       setParams({...params, toDate: e.target.value})
                                   }}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{"paddingTop": "10px", marginTop: '10px'}}>
                    <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName}
                                    onChange={(e: any) => setParams({...params, FirstName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI}
                                    onChange={(e: any) => setParams({...params, MI: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName}
                                    onChange={(e: any) => setParams({...params, LastName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB"
                                    InputLabelProps={{shrink: true}} value={params.DOB}
                                    onChange={(e: any) => setParams({...params, DOB: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Carrier Name" value={params.CarrierName}
                                    onChange={(e: any) => setParams({...params, CarrierName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="US DOT #" value={params.DOT}
                                    onChange={(e: any) => setParams({...params, DOT: e.target.value})}/></div>
                   <div><TextField margin='none' size={"small"} fullWidth label="DL #" value={params.DL}
                                    onChange={(e: any) => setParams({...params, DL: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Plate #" value={params.Plate}
                                    onChange={(e: any) => setParams({...params, Plate: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="VIN" value={params.VIN}
                                    onChange={(e: any) => setParams({...params, VIN: e.target.value})}/></div>
                </div>
                {/* <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop": "25px"}}>
                    <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"ECrawl"}/></div>
                </div> */}
                <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                    <ButtonGroup size="large" color="inherit">
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>
            </div>
                      
        </>
    )
})

export default ECrawlReportSearch;
