import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import {getGoogleAnalyticsTrackingCode} from "./services/config.service";

ReactGA.initialize(getGoogleAnalyticsTrackingCode(),{debug:false, gaOptions:{siteSpeedSampleRate:100}});
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.exception({ description: 'An error ocurred!', fatal: true });

ReactDOM.render(
  <React.StrictMode>
          <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
