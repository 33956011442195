//SET to 'false' if deploying to Production without the quotes

import {
    GetPanelsForSite,
    GetToolsForSite,
    IsToolEnabled,
    PERSON_PANEL,
    SITE,
    TOOLS
} from "../interfaces/config.interface"

export const SiteName: SITE = SITE.NJPROD  //LOCAL, NJDEMO, NJUAT, NJPROD, DCLOCAL, DCDEMO, DCUAT, DCPROD, NYLOCAL, NYDEMO, NYPROD
export const ENABLED_TOOLS: TOOLS[] = GetToolsForSite(SiteName);

export const ENABLED_PANELS: PERSON_PANEL[] = GetPanelsForSite(SiteName);
export const BTVERSION: string = '6.0.17.3'
export const BTVERSION_RELEASE_DT: string = '1/24/2025'

export function getGoogleAnalyticsTrackingCode() {
    if (SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT || SiteName === SITE.NJPROD)
        return 'UA-105282168-1'
    else if (SiteName === SITE.DCDEMO || SiteName === SITE.DCUAT || SiteName === SITE.DCPROD)
        return 'G-FHMNSF4GL7'
    else if (SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD)
        return 'G-F9XR7QBX4R'
    else
        return 'UA-105282168-1'
}

export function getSiteName(sDomain: SITE, sProduct: string): string {
    if (sProduct === 'SHOOTING') {
        if (sDomain === SITE.NJDEMO)
            return "https://demoNJPOP.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demoNJPOP.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://shoothit.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoshoothit.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatshoothit.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcshoothit.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoshoothit.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysshoothit.backtracereports.com"
        else
            return "https://localhost:44366"
    }
    else if (sProduct === 'NIBIN') {
        if (sDomain === SITE.NJDEMO)
            return "https://demoNIBIN.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demoNIBIN.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://nibin.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemonibin.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatnibin.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcnibin.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemonibin.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysnibin.backtracereports.com"
        else
            return "https://localhost:44367"
    }
    else if (sProduct === 'HMNTRFC') {
        if (sDomain === SITE.NJDEMO)
            return "https://demoHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demoHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://HumanTraffic.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoHumanTraffic.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysHumanTraffic.backtracereports.com"
        else
            return "https://localhost:44368"
    }
    else if (sProduct === 'LINKANALYSIS') {
        if (sDomain === SITE.NJDEMO)
            return "https://demolinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demolinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://linkanalysis.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemolinkanalysis.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatlinkanalysis.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdclinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemolinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nyslinkanalysis.backtracereports.com"
        else
            return "https://localhost:44369"
    }
    else if (sProduct === 'THREAT' || sProduct === 'THREAT_API') {
        if (sDomain === SITE.NJDEMO)
            return "https://demoThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demoThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://ThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoThreatAssessment.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysThreatAssessment.backtracereports.com"
        else
            return "https://localhost:44370"
    }
    else if (sProduct === 'NJSTAD' || sProduct === 'NJSTAD_API') {
        if (sDomain === SITE.NJDEMO || sDomain === SITE.LOCAL)
            return "https://njstad.njapps.org"
        else if (sDomain === SITE.NJUAT)
            return "https://njstad.njapps.org"
        else if (sDomain === SITE.NJPROD)
            return "https://njstad.backtracereports.com"
        else if (sDomain === SITE.DCDEMO || sDomain === SITE.DCLOCAL)
            return "https://mpdcdemostad.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatstad.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcstad.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemostad.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysstad.backtracereports.com"
        else
            return "https://localhost:8080"
    }
    else if (sProduct === 'EOS') {
        if (sDomain === SITE.NJDEMO)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.NJUAT)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.NJPROD)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.DCUAT)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.DCPROD)
            return "https://eyeonscene.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://eyeonscene.org"
        else if (sDomain === SITE.NYPROD)
            return "https://eyeonscene.org"
        else
            return "https://localhost:44371"
    }
    else if (sProduct === 'NOTIFICATION') {
        if (sDomain === SITE.NJDEMO)
            return "https://demolists.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demolists.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://linkanalysis.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemolists.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatlists.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdclinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemolinkanalysis.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nyslinkanalysis.backtracereports.com"
        else
            return "https://localhost:44372"
    }
    else if (sProduct === 'DEVICELOCATION') {
        if (sDomain === SITE.NJDEMO)
            return "https://demoDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://demoDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://DeviceLocation.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoDeviceLocation.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysDeviceLocation.backtracereports.com"
        else
            return "http://localhost:8080"
    }
    else if (sProduct === 'MAIN') {
        if (sDomain === SITE.NJDEMO)
            return "https://demo.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://uat.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemo.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuat.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdc.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemo.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nys.backtracereports.com"
        else
            return "http://localhost:3000"
    }
    else if (sProduct === 'NJSTAD') {
        if (sDomain === SITE.NJDEMO)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.NJUAT)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.NJPROD)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.DCDEMO)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatstad.devlocal.host"
        else if (sDomain === SITE.DCPROD)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.NYDEMO)
            return "https://njstad.devlocal.host"
        else if (sDomain === SITE.NYPROD)
            return "https://njstad.devlocal.host"
        else
            return "https://localhost:44375"
    }
    else if (sProduct === 'ALPR') {
        //
        //DEV: "https://localhost:44365", DEMO: "https://demoapi.backtracereports.com", PROD: "https://api.backtracereports.com"
        //
        if (sDomain === SITE.NJDEMO)
            return "https://demoalpr.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://uatalpr.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://alpr.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoalpr.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatalpr.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcalpr.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoalpr.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysalpr.backtracereports.com"
        else
            return "https://localhost:44365"  /* "https://demoalpr.backtracereports.com"*/
    }
    else {   //default BACKTRACE
        //
        //DEV: "https://localhost:44365", DEMO: "https://demoapi.backtracereports.com", PROD: "https://api.backtracereports.com"
        //
        if (sDomain === SITE.NJDEMO)
            return "https://demoapi.backtracereports.com"
        else if (sDomain === SITE.NJUAT)
            return "https://uatapi.backtracereports.com"
        else if (sDomain === SITE.NJPROD)
            return "https://api.backtracereports.com"
        else if (sDomain === SITE.DCDEMO)
            return "https://dcdemoapi.backtracereports.com"
        else if (sDomain === SITE.DCUAT)
            return "https://dcuatapi.backtracereports.com"
        else if (sDomain === SITE.DCPROD)
            return "https://mpdcapi.backtracereports.com"
        else if (sDomain === SITE.NYDEMO)
            return "https://nysdemoapi.backtracereports.com"
        else if (sDomain === SITE.NYPROD)
            return "https://nysapi.backtracereports.com"
        else
            return "https://localhost:44365"
    }
}

export const API_URL: string = getSiteName(SiteName, 'BACTRACE')
export const Google_API_Key: string = "AIzaSyBRnGulaLFJWacrgj1hpgHiO1HEoEyUuDA"
export const ALPRTimeout_Sec: number = 1000 //(300 * 1000) 5 Minute ALPR timeout for any server fetch
export const NJPOP: string = getSiteName(SiteName, 'SHOOTING')
export const NIBIN_URL: string = getSiteName(SiteName, 'NIBIN')
export const HMNTRFC_URL: string = getSiteName(SiteName, 'HMNTRFC')
export const LINKANALYSIS: string = getSiteName(SiteName, 'LINKANALYSIS')
export const THREAT_URL: string = getSiteName(SiteName, 'THREAT')
export const THREAT_API_URL: string = getSiteName(SiteName, 'THREAT_API')
export const NJSTAD_API_URL: string = getSiteName(SiteName, 'NJSTAD_API')
export const EOS_URL: string = getSiteName(SiteName, 'EOS')
export const NOTIFICATIONS_URL: string = getSiteName(SiteName, 'NOTIFICATION')
export const DEVICELOCATION_URL: string = getSiteName(SiteName, 'DEVICELOCATION')
export const DashboardTimer: number = 600000   //10 minutes refresh of Dashboard
export const DashboardRefresh: number = 3000000 //50 minutes Dashboard Token Refresh
export const DashboardMarqueeTimer: number = 600000 //10 minutes refresh of Dashboard Shooting Marquee
export const showCrumbScore: boolean = IsToolEnabled(TOOLS.SCOR_SEARCH, ENABLED_TOOLS); //true if want to show in graphs
export const enableNJCAP5: boolean = true; //true if want to enable NJCAP5
export const Main_URL: string = getSiteName(SiteName, 'MAIN')
export const NJSTAD_URL: string = getSiteName(SiteName, 'NJSTAD')
export const ALPR_URL: string = getSiteName(SiteName, 'ALPR')