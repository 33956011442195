import React, {useState} from "react";
import {InputAdornment, TextField} from "@mui/material";
import TagIcon from '@mui/icons-material/Tag';

interface DashboardDataGridProps {
    location:string,
    data:any
}

const DashboardGoalsGrid: React.FC<DashboardDataGridProps> = ({location, data}) => {
    const [percentChange, setPercentChange] = React.useState<number>(0)
    const [lastYearTotal, setLastYearTotal] = React.useState<number>(0)
    const [calculatedProjection, setCalculatedProjection] = React.useState<number>(0)
    let today = new Date()
    const [changeType, setChangeType] = React.useState<string>('')
    const [goalPercentage, setGoalPercentage] = React.useState<number>(0)
    const [targetValue, setTargetValue] = React.useState<any>(750) //2024: 899

    React.useEffect(() => {
        let change:any = 0
        let lastYear:any = 0
        //Get Last Year Total
        if(data.YearlyData)
        {
            let x = data.YearlyData.find((e:any) => e?.Year === (new Date().getFullYear() - 1))
            if(x && x.hasOwnProperty('Alive') && x.hasOwnProperty('Dead'))
            {
                lastYear = (x.Alive + x.Dead)
                setLastYearTotal(lastYear)
                getGoalPercentage()
            }
        }
        //Get current % change YTD
        if(data.YTDData)
        {
            let decrease = data.YTDData[1]?.YTDCount - data.YTDData[0]?.YTDCount
            change = (decrease / data.YTDData[1]?.YTDCount) * 100
            if(Math.sign(change) === -1)
            {
                setChangeType('Increase')
                change = (change * -1)
            }
            else
            {
                setChangeType('Decrease')
            }
            change = (Math.round(change * 100) / 100)
            //Get Last Year total - ( change * last Year total) (this year prediction)
            let projection = lastYear - (lastYear * (change/100))
            setCalculatedProjection(Math.floor(projection))
        }
        setPercentChange(change)
    }, [data]);
    
    
    /*UPDATE: 9/22/2023 This shows projection from 2022 (They decided they no longer wanted to show this)
    React.useEffect(() => {
        if(priorLocation !== location){
            if(location === 'ALL NJ'){
                setTargetValue(999)
            }else {
                getTargetReduction()
            }
            setPriorLocation(location)
        }
        let lastYear = 0
        //Get Last Year Total
        if(data.YearlyData)
        {
            let x = data.YearlyData.find((e:any) => e?.Year === (new Date().getFullYear() - 1))
            if(x && x.hasOwnProperty('Alive') && x.hasOwnProperty('Dead'))
            {
                lastYear = (x.Alive + x.Dead)
                setLastYearTotal(lastYear)
                getGoalPercentage()
            }
        }
        
        //Figure out how many shooting victims per day.
        //UPDATE: 9/22/2023 They decided that they didnt want this anymore
        var yearEnd=new Date(today.getFullYear(), 11, 31);
        
        if (today.getMonth() == 11 && today.getDate() == 31)
        {
            yearEnd.setFullYear(yearEnd.getFullYear()+1);
        }
        
        var one_day= 1000 * 60 * 60 * 24;
        let daysLeft = Math.ceil((yearEnd.getTime() - today.getTime()) /(one_day) )
        let current = 365 - daysLeft
        
        if(data.YTDData) {
            
            let temp = data?.YTDData.find((e:any) => e.Year === today.getFullYear()) // ytd this year
            if(temp && temp.hasOwnProperty('YTDCount'))
            {
                let YTDCount = temp.YTDCount
                //Calculate current Projection Based on Last year
                let perDay = YTDCount / current
                perDay = Math.round((perDay + Number.EPSILON) * 10) / 10
                let projection = perDay * daysLeft
                projection = Math.round(projection)
                let cp = (YTDCount + projection)
                setCalculatedProjection(cp)
                
                let decrease = lastYear - cp
                let change = (decrease / lastYear) * 100
                if(Math.sign(change) === -1)
                {
                    change = (change * -1)
                    setPercentChange(change)
                }
                change = Math.round((change + Number.EPSILON) * 10) / 10
                setPercentChange(change)
            }
        }
    
    }, [data]);*/
    
    /* NO LONGER USED SINCE WE ARE ONLY SHOWING FOR ALL NJ
    const getTargetReduction = () => {
        if(location !== 'ALL NJ') {
            let lastYear = 0
            //Get Last Year Total
            if(data.YearlyData)
            {
                let x = data.YearlyData.find((e:any) => e?.Year === (new Date().getFullYear() - 1))
                if(x && x.hasOwnProperty('Alive') && x.hasOwnProperty('Dead'))
                {
                    lastYear = (x.Alive + x.Dead)
                }
            }
            
            let twentyPercentReduction = lastYear - (lastYear * 0.2)
            setTargetValue(Math.round(twentyPercentReduction))
        }else
        {
            setTargetValue(999)
        }
    }*/

    const getGoalPercentage = (tv:any = targetValue) =>{
        let lastYear = 0
        //Get Last Year Total
        if(data.YearlyData)
        {
            let x = data.YearlyData.find((e:any) => e?.Year === (new Date().getFullYear() - 1))
            if(x && x.hasOwnProperty('Alive') && x.hasOwnProperty('Dead'))
            {
                lastYear = (x.Alive + x.Dead)
            }
        }
        
        let decrease = lastYear - tv 
        let change = (decrease / lastYear) * 100

        if(Math.sign(change) === -1)
        {
            change = (change * -1)
        }
        
        change = Math.round((change + Number.EPSILON) * 100) / 100
        setGoalPercentage(change)
        
    }

    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-medium">
                Goals
                <div className="font-semibold text-xs">{location}</div>
            </div>
            <div className = "border-gray-900 border-t-2">
                {data.YearlyData ? 
                    <div>   
                        <div className={"text-center font-bold"} style={{fontSize:'14px'}}>
                            <span>{today.getFullYear()} Goal</span>
                            <span className={"text-blue-600"}> {goalPercentage}%</span>
                            <span> (Based on { (today.getFullYear() - 1) })</span>
                        </div>
                        <div className={"text-center font-bold"} style={{fontSize:'14px'}}>Last Year ({(today.getFullYear() - 1)}): {lastYearTotal}</div>
                        <div className={"text-center font-bold mt-3"} style={{fontSize:'14px'}}>
                            <span style={{display:'inline'}} >
                                <TextField 
                                    label={'(' + today.getFullYear() + ') Target'}
                                    style={{width:'70%', textAlign:'center'}}
                                    variant="outlined"
                                    margin='none'
                                    fullWidth
                                    size={"small"}
                                    type={'number'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TagIcon />
                                            </InputAdornment>
                                        ),}}
                                    value={targetValue}
                                    onChange={(e) => {setTargetValue(e.target.value); getGoalPercentage(e.target.value) }}
                                />
                            </span>
                            {location !== 'ALL NJ' ? <div style={{fontSize:'10px'}}>Default based on 20% reduction from previous year</div> : <></>}
                        </div>
                        <div>
                            {/*<div className={"text-center font-bold mt-1"} style={{fontSize:'14px'}}>{today.getFullYear()} Projection</div>*/}
                            <div className={"text-center font-bold mt-1"} style={{fontSize:'14px'}}>
                                <span className = {targetValue < calculatedProjection ? "text-center font-bold text-red-600" : "text-center font-bold text-green-500"}>{calculatedProjection}</span>
                                <span> Projected victims based on {lastYearTotal} at</span>
                                <span className = {targetValue < calculatedProjection ? "text-center font-bold text-red-600" : "text-center font-bold text-green-500"}> {percentChange}%</span>
                            </div>
                        </div>
                    </div>
                : <></>}
            </div>
        </div>

    );
}

export default DashboardGoalsGrid;

