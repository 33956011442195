import React, {useState, useEffect} from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import {convertUTCtoLocalTimeRange, formatDate} from "../../../services/formatDate.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import {Disclosure} from "@headlessui/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


interface DetailProps {
    rpt: any | null
}

const M43OffenseReport: React.FC<DetailProps> = ({rpt}) => {
    
    const PersonImg = (person:any) => {
        let pQuery : any = {
            DOB: person?.dateOfBirth,
            FBI:  person?.fbiNumber,
            SBINo:  person?.stateIdNumber,
            FName:  person?.firstName,
            MName:  person?.middleName,
            LName:  person?.lastName

        }

        return (
            <BTPersonGridImage person={pQuery} />
        )
    }
    
    const getDistrictPSA= (rpt:any) => {
        if( rpt?.externalOffenses){
            for (let offense of rpt?.externalOffenses){
                if(offense?.offenseLocation?.subdivision1 || offense?.offenseLocation?.subdivision2)
                    return `${offense?.offenseLocation?.subdivision1}/${offense?.offenseLocation?.subdivision2}`
            }
        }
        return "";
    }
    
    return (
        <div className="bg-white p-6 max-w-4xl mx-auto shadow-lg border border-gray-300 mt-10 ">
            <div className="bg-blue-900 text-white p-2">
                <h1 className="text-xl font-bold">CCN# {rpt.reportNumber} - Offense/Incident Report</h1>
            </div>
            <div className="grid grid-cols-3">
                <div className="border-r border-b border-gray-300 pl-2 ">
                    <p className="font-bold text-xs	">REPORT DATE / TIME</p>
                    <p> {convertUTCtoLocalTimeRange(rpt.createdDateUtc,null)}</p>
                </div>
                <div className="border-r border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs	">DISTRICT/PSA</p>
                    <p>{getDistrictPSA(rpt)}</p>
                </div>
                <div className="border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs">EVENT START DATE / TIME - EVENT END DATE / TIME</p>
                    <p>{convertUTCtoLocalTimeRange(rpt.eventStartUtc,rpt.eventEndUtc)}</p>
                </div>
            </div>
            {/*<div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">REPORT AUTHOR</p>
                                    <p></p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">WEATHER</p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ASSISTING PERSONNEL/TYPE(S)</p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">REPORT TAKEN LOCATION</p>
                                    <p></p>
                                </div>
                            </div>*/}
            {/*<div className="grid grid-cols-5">
                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">STREET CRIME</p>
                                    <p>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="YES"/>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="NO"/>
                                    </p>
                                </div>
                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SHOTS FIRED</p>
                                    <p>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="YES"/>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="NO"/>
                                    </p>
                                </div>
                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SHOTS EFFECT</p>
                                    <p>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="YES"/>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="NO"/>
                                    </p>
                                </div>
                                <div className="col-span-2 border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">WERE THERE PARAMEDICS AT THE SCENE OF THE
                                        EVENT?</p>
                                    <p>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="YES"/>
                                        <FormControlLabel sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                          control={<Checkbox
                                                              sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                          label="NO"/>
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">UNIT</p>
                                    <p></p>
                                </div>
                            </div>*/}
            <div className="grid grid-cols-1">
                <div className="border-b border-gray-300 pl-2">
                    <p className="font-bold text-xs	">STAT REPORTING</p>
                    <p className="pl-2">
                        <Box sx={{display: 'flex'}}>
                            <FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="School Zone" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('School Zone')} />
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Public Housing" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Public Housing')} />
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Offense Occurred On Street Car Platform" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Offense Occurred On Street Car Platform')} />
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="ABC Violation or Incident" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('ABC Violation or Incident')} />
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Offense Occurred Inside Street Car" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Offense Occurred Inside Street Car')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Offense Occurred on Street Car Track" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Offense Occurred on Street Car Track')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="GPS Tracking Device (on personal article)" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('GPS Tracking Device (on personal article)')}/>
                                </FormGroup>
                            </FormControl>
                            <FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="White House Involved" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('White House Involved')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="NOI or NOV Issued" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('NOI or NOV Issued')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="School-Based Event" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('School-Based Event')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="GPS Tracking Device (by vehicle manufacturer)" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('GPS Tracking Device (by vehicle manufacturer)')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Referred to PAD Program" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Referred to PAD Program')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="GPS Tracking Device (in vehicle)" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('GPS Tracking Device (in vehicle)')}/>
                                    <FormControlLabel
                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                        control={<Checkbox
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                        label="Naloxone (Narcan) deployed by MPD" disabled={true} checked={rpt?.eventStatisticsDisplayNames?.includes('Naloxone (Narcan) deployed by MPD')}/>
                                </FormGroup>
                            </FormControl>
                        </Box>
                    </p>
                </div>
            </div>
            {/*<div className="border-b border-gray-300">
                                <h2 className=" text-xs bg-blue-900 text-white p-2">NOTIFICATIONS</h2>
                                <div className="grid grid-cols-2">
                                    <div className="border-r border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">NOTIFICATION-1 MADE</p>
                                        <p></p>
                                    </div>
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold  text-xs">NOTIFICATION DATE</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1">
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">NOTIFICATION DETAILS</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>*/}
            {rpt.summaryNarrative != rpt.narrative &&
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">PUBLIC NARRATIVE</h2>
                    <p style={{overflow: 'auto'}}>
                        <pre className="text-xs" style={{whiteSpace: 'pre-wrap'}}>{rpt.summaryNarrative}</pre>
                    </p>
                </div>
            }
            <div className="border-b border-gray-300">
                <h2 className="font-bold text-base bg-blue-900 text-white p-2">INTERNAL NARRATIVE</h2>
                <p style={{overflow: 'auto'}}>
                    <pre className="text-xs" style={{whiteSpace: 'pre-wrap'}}>{rpt.narrative}</pre>
                </p>
            </div>

            {rpt?.reportingPersons && rpt?.reportingPersons?.map((person: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">REPORTING PERSON - {idx + 1}</h2>

                    <div className="grid grid-cols-6">
                        <div>{PersonImg(person)}&nbsp;</div>
                        <div className={"col-span-5"}>
                            <div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                        MIDDLE)</p>
                                    <p>{person?.lastName?.toUpperCase()}, {person?.firstName?.toUpperCase()} {person?.middleName?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Date Of Birth</p>
                                    <p>{person?.dateOfBirth}&nbsp;</p>
                                </div>

                            </div>

                            <div className="grid grid-cols-5">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PDID#</p>
                                    <p>{person?.stateIdNumber}&nbsp;</p>
                                </div>

                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">FBI#</p>
                                    <p>{person?.fbiNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DL#</p>
                                    <p>{person?.licenseState} {person?.licenseNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SSN#</p>
                                    <p>{person?.ssn}&nbsp;</p>
                                </div>

                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Phone#</p>
                                    <p>{person?.phoneNumber}&nbsp;</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SEX</p>
                                    <p>{person?.sexDisplayName?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                    <p>{person?.eyeColorDisplayName?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">RACE</p>
                                    <p>{person?.raceDisplayName?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className=" border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                    <p>{person?.ethnicityDisplayName?.toUpperCase()}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                            <p>{person?.homeAddress?.streetNumber} {person?.homeAddress?.streetName?.toUpperCase()}</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{person?.homeAddress?.city?.toUpperCase()}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{person?.homeAddress?.state?.toUpperCase()}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{person?.homeAddress?.zip?.toUpperCase()}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{person?.homeAddress?.country}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{person?.homeAddress?.crossStreet1?.toUpperCase()}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{person?.homeAddress?.crossStreet2?.toUpperCase()}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{person?.homeAddress?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{person?.homeAddress?.subdivision1}/{person?.homeAddress?.subdivision2}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{person?.homeAddress?.locationPropertyType}</p>
                        </div>
                    </div>

                </div>
            ))}

            {/*<div className="border-b border-gray-300">
                                <h2 className=" text-xs bg-blue-900 text-white p-2">REPORTING PARTY-1</h2>
                                <div className="grid grid-cols-1">
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">REPORTING PARTY-1 (ORGANIZATION)</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="border-r border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">ORGANIZATION TYPE</p>
                                        <p></p>
                                    </div>
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold  text-xs">ORGANIZATION INDUSTRY</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">PHYSICAL ADDRESS</p>
                                        <p></p>
                                    </div>
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold  text-xs">BEEN AT LOCATION SINCE</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">MAILING ADDRESS</p>
                                        <p></p>
                                    </div>
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold  text-xs">BEEN AT LOCATION SINCE</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="border-r border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">EMAIL ADDRESS</p>
                                        <p></p>
                                    </div>
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold  text-xs">PHONE NUMBER</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1">
                                    <div className="border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs">NAME IDENTIFIER</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
*/}
                    {rpt?.externalOffenses && rpt?.externalOffenses?.map((offense: any, idx: number) => (
                        <>
                            <Disclosure defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="font-bold text-base bg-blue-900 text-white p-2 w-full	text-left">
                                                <span >
                                                    <span>
                                                        <KeyboardArrowDownIcon
                                                            className={`${open ? "transform rotate-180" : ""}`}
                                                        />
                                                    </span>
                                                   OFFENSE - {idx + 1}
                                                </span>
                                        </Disclosure.Button>

                                        <Disclosure.Panel >

                                            <div className="border-b border-gray-300">
                                                <div className="grid grid-cols-1">
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">OFFENSE CODE</p>
                                                        <p>{offense.externalOffenseCode.displayValue?.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">OFFENSE LEVEL STATUS</p>
                                                        <p>{offense.externalOffenseCode.offenseClassificationCode?.toUpperCase()}</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">OFFENSE START DATE</p>
                                                        <p>{convertUTCtoLocalTimeRange(offense.offenseDateUtc, null)}</p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">OFFENSE END DATE</p>
                                                        <p>{convertUTCtoLocalTimeRange(offense.offenseEndDateUtc, null)}</p>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-4">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">OFFENSE COMPLETION</p>
                                                        <p>
                                                            <FormControl>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                        control={<Checkbox disabled={true}
                                                                                           checked={offense?.completed}
                                                                                           sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                        label="COMPLETED"/>
                                                                    <FormControlLabel
                                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                        control={<Checkbox disabled={true}
                                                                                           checked={!offense?.completed}
                                                                                           sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                        label="ATTEMPTED"/>
                                                                </FormGroup>
                                                            </FormControl>
                                                        </p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">SUSPECTED HATE CRIME</p>
                                                        <p>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox disabled={true}
                                                                                   checked={offense?.suspectedHateCrime}
                                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="YES"/>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox disabled={true}
                                                                                   checked={!offense?.suspectedHateCrime}
                                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="NO"/>
                                                        </p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">HATE CRIME ACT</p>
                                                        <p>
                                                            <ul>
                                                                {offense?.offenseAttributes?.filter((a: any) => a?.attributeType === "BIAS_MOTIVATION")?.map((attr: any, idx: number) => (
                                                                    <li>{attr.displayValue?.toUpperCase()} {attr.description?.toUpperCase()}</li>
                                                                ))}
                                                            </ul> &nbsp;
                                                        </p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">DOMESTIC VIOLENCE</p>
                                                        <p>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox disabled={true}
                                                                                   checked={offense?.domesticViolence}
                                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="YES"/>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox disabled={true}
                                                                                   checked={!offense?.domesticViolence}
                                                                                   sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="NO"/>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-3">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">GANG ACTIVITY</p>
                                                        <p>
                                                            <ul>
                                                                {offense?.offenseAttributes?.filter((a: any) => a?.attributeType === "GANG_INFORMATION")?.map((attr: any, idx: number) => (
                                                                    <li>{attr.displayValue?.toUpperCase()} {attr.description?.toUpperCase()}</li>
                                                                ))}
                                                            </ul>
                                                            &nbsp;</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">WEAPON / FORCE INVOLVED</p>
                                                        <p>
                                                            <ul>
                                                            { offense?.offenseAttributes?.filter((a: any) => a?.attributeType === "WEAPON_OR_FORCE_INVOLVED")?.map((attr: any, idx: number) => (
                                                                <li>{attr.displayValue} {attr.description?.toUpperCase()}</li>
                                                            ))}
                                                            </ul>
                                                            &nbsp;
                                                        </p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs">Modus Operandi</p>
                                                        <p>
                                                            <ul>
                                                            { offense?.offenseAttributes?.filter((a: any) => a?.attributeType === "MODUS_OPERANDI")?.map((attr: any, idx: number) => (
                                                                <li>{attr.displayValue?.toUpperCase()} {attr.description?.toUpperCase()}</li>
                                                            ))}
                                                            </ul>
                                                            &nbsp;
                                                        </p>
                                                    </div>
                                                </div>

                                                {/*<div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">IS EVIDENCE AVAILABLE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">NUMBER OF WEAPONS INVOLVED</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">DISPOSITION</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-5">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTED STREET GANG?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTED OTHER ORGANIZED GROUP?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="col-span-2 border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTED COMPUTER/ HANDHELD DEVICE
                                                    USE</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-4">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTED ALCOHOL CONSUMPTION BY ANY
                                                    INVOLVED
                                                    PARTY?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTED DRUG USE BY ANY INVOLVED
                                                    PARTY?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">DOMESTIC VIOLENCE</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PROTECTION (RESTRAINING) ORDER</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PROTECTION ORDER STATUS</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">ORDER TYPE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">ISSUING COURT</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PROTECTION ORDER DOCKET NUMBER</p>
                                                <p></p>
                                            </div>
                                            <div className="col-span-2 border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WEAPON / FORCE INVOLVED</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HATE BIAS / MOTIVATION</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">AGGRAVATED ASSAULT FACTORS / HOMICIDE
                                                    FACTORS</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">NEGLIGENT MANSLAUGHTER CIRCUMSTANCE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">CRIMINAL ACTIVITIES / ANIMAL
                                                    CRUELTY</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">TYPE OF CRIMINAL ACTIVITY</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WAS METHOD OF ENTRY FORCED?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HOW MANY PREMISES ENTERED?</p>
                                                <p></p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-4">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SECURITY SYSTEM</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">INCLUDES CARGO THEFT</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">INVOLVES CARJACKING?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WAS VEHICLE BUMPED/ROBBED?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WAS WITNESS PRESENT?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">INVOLVES A SHOOTING?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">MODUS OPERANDI</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WAS THE BUILDING INHABITED?</p>
                                                <p>
                                                    <FormControl>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox
                                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="INHABITED"/>
                                                            <FormControlLabel
                                                                sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                control={<Checkbox
                                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                label="UNINHABITED, ABANDONED, OR NOT NORMALLY IN USE"/>
                                                        </FormGroup>
                                                    </FormControl>

                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-1">
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">OFFENSE STATISTICS</p>
                                                <p className="pl-2">
                                                    <Box sx={{display: 'flex'}}>
                                                        <FormControl>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                    control={<Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                    label="Gang Related(DO NOT USE)"/>
                                                                <FormControlLabel
                                                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                    control={<Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                    label="Gun Involved"/>
                                                                <FormControlLabel
                                                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                    control={<Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                    label="Public Housing"/>

                                                            </FormGroup>
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                    control={<Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                    label="Child Abuse"/>
                                                                <FormControlLabel
                                                                    sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                                    control={<Checkbox
                                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                                    label="School Zone"/>
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Box>
                                                </p>
                                            </div>
                                        </div>*/}
                                                {/*<div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">ABUSE TYPE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">POLICE ACTION</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">IS ANTI-REPRODUCTIVE RIGHTS CRIME?</p>
                                                <p></p>
                                            </div>
                                        </div>*/}

                                            </div>

                                            <div className="border-b border-gray-300">
                                                <h2 className=" text-xs bg-blue-900 text-white p-2">OFFENSE
                                                    LOCATION</h2>
                                                <div className="grid grid-cols-1">
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">OFFENSE LOCATION NAME /
                                                            STREET
                                                            ADDRESS</p>
                                                        <p>{offense?.offenseLocation?.placeName?.toUpperCase()} {offense?.offenseLocation?.streetNumber} {offense?.offenseLocation?.streetName?.toUpperCase()}</p>
                                                    </div>

                                                </div>
                                                <div className="grid grid-cols-4">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">CITY</p>
                                                        <p>{offense?.offenseLocation?.city?.toUpperCase()}</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">STATE</p>
                                                        <p>{offense?.offenseLocation?.state?.toUpperCase()}</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">ZIP</p>
                                                        <p>{offense?.offenseLocation?.zip?.toUpperCase()}</p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">COUNTRY</p>
                                                        <p>{offense?.offenseLocation?.country?.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-4">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                                                        <p>{offense?.offenseLocation?.crossStreet1?.toUpperCase()}&nbsp;</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                                                        <p>{offense?.offenseLocation?.crossStreet2?.toUpperCase()}&nbsp;</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	"> LATITUDE</p>
                                                        <p>{offense?.offenseLocation?.latitude}&nbsp;</p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">LONGITUDE</p>
                                                        <p>{offense?.offenseLocation?.longitude}&nbsp;</p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3">
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                                                        <p>{offense?.offenseLocation?.category}</p>
                                                    </div>
                                                    <div className="border-r border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">DISTRICT / PSA</p>
                                                        <p>{offense?.offenseLocation?.subdivision1}/{offense?.offenseLocation?.subdivision2}</p>
                                                    </div>
                                                    <div className="border-b border-gray-300 pl-2">
                                                        <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                                                        <p>{offense?.offenseLocation?.locationPropertyType}</p>
                                                    </div>
                                                </div>

                                            </div>

                                            {offense?.involvedPersons && offense?.involvedPersons?.filter((p: any) => p?.lastName || p?.firstName)?.map((person: any, idx: number) => (
                                                <div className="border-b border-gray-300">
                                                    <h2 className=" text-xs bg-blue-900 text-white p-2">{person?.involvement ? person?.involvement?.replace('_IN_OFFENSE', '') : "OTHER"}</h2>

                                                    <div className="grid grid-cols-6">
                                                        <div>{PersonImg(person)}&nbsp;</div>
                                                        <div className={"col-span-5"}>
                                                            <div className="grid grid-cols-3">
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                                                        MIDDLE)</p>
                                                                    <p>{person?.lastName?.toUpperCase()}{person?.firstName ? ',' : ''} {person?.firstName?.toUpperCase()} {person?.middleName?.toUpperCase()}&nbsp;</p>
                                                                </div>
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">DATE OF BIRTH</p>
                                                                    <p>{person?.dateOfBirth}&nbsp;</p>
                                                                </div>
                                                                <div className="border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">DATE OF DEATH</p>
                                                                    <p>{formatDate(person?.dateOfDeath)}&nbsp;</p>
                                                                </div>

                                                            </div>
                                                            <div className="grid grid-cols-5">
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">PDID#</p>
                                                                    <p>{person?.stateIdNumber}&nbsp;</p>
                                                                </div>

                                                                <div className="border-b border-r border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">FBI#</p>
                                                                    <p>{person?.fbiNumber}&nbsp;</p>
                                                                </div>
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">DL#</p>
                                                                    <p>{person?.licenseState} {person?.licenseNumber}&nbsp;</p>
                                                                </div>
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">SSN#</p>
                                                                    <p>{person?.ssn}&nbsp;</p>
                                                                </div>

                                                                <div className="border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">Phone#</p>
                                                                    <p>{person?.phoneNumber}&nbsp;</p>
                                                                </div>
                                                            </div>
                                                            <div className="grid grid-cols-4">
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">SEX</p>
                                                                    <p>{person?.sexDisplayName?.toUpperCase()}&nbsp;</p>
                                                                </div>
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                                                    <p>{person?.eyeColorDisplayName?.toUpperCase()}&nbsp;</p>
                                                                </div>
                                                                <div className="border-r border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">RACE</p>
                                                                    <p>{person?.raceDisplayName?.toUpperCase()}&nbsp;</p>
                                                                </div>
                                                                <div className=" border-b border-gray-300 pl-2">
                                                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                                                    <p>{person?.ethnicityDisplayName?.toUpperCase()}&nbsp;</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {person?.personInjuries && person?.personInjuries?.length > 0 && (
                                                        <div className="grid grid-cols-1">
                                                            <div className=" border-b border-gray-300 pl-2">
                                                                <p className="font-bold text-xs	">INJURIES</p>
                                                                <p>
                                                                    <ul>
                                                                        {person?.personInjuries && person?.personInjuries?.map((injury: any) => (
                                                                            <li>{injury?.injuryTypeName}, {injury?.bodyPartName}, {injury?.injuryDescription} </li>
                                                                        ))}
                                                                    </ul>
                                                                    &nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {person?.identifyingMarks && person?.identifyingMarks?.length > 0 && (
                                                        <div className="grid grid-cols-1">
                                                            <div className=" border-b border-gray-300 pl-2">
                                                                <p className="font-bold text-xs	">IDENTIFYING MARKS</p>
                                                                <p>
                                                                    <ul>
                                                                        {person?.identifyingMarks && person?.identifyingMarks?.map((marks: any) => (
                                                                            <li>{marks?.identifyingMarkType}, {marks?.identifyingMarkLocation}, {marks?.identifyingMarkDescription} </li>
                                                                        ))}
                                                                    </ul>
                                                                    &nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {person?.description && (<div className="grid grid-cols-1">
                                                        <div className="border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">DESCRIPTION</p>
                                                            <p><pre>{person?.description}</pre>&nbsp;</p>
                                                        </div>
                                                    </div>)}


                                                    <div className="grid grid-cols-1">
                                                        <div className="border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                                                            <p>{person?.homeAddress?.streetNumber} {person?.homeAddress?.streetName?.toUpperCase()}</p>
                                                        </div>

                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">CITY</p>
                                                            <p>{person?.homeAddress?.city?.toUpperCase()}</p>
                                                        </div>
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">STATE</p>
                                                            <p>{person?.homeAddress?.state?.toUpperCase()}</p>
                                                        </div>
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">ZIP</p>
                                                            <p>{person?.homeAddress?.zip?.toUpperCase()}</p>
                                                        </div>
                                                        <div className="border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">COUNTRY</p>
                                                            <p>{person?.homeAddress?.country?.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2">
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                                                            <p>{person?.homeAddress?.crossStreet1?.toUpperCase()}&nbsp;</p>
                                                        </div>
                                                        <div className="border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                                                            <p>{person?.homeAddress?.crossStreet2?.toUpperCase()}&nbsp;</p>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-4">
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                                                            <p>{person?.homeAddress?.category}</p>
                                                        </div>
                                                        <div className="border-r border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                                                            <p>{person?.homeAddress?.subdivision1}/{person?.homeAddress?.subdivision2}</p>
                                                        </div>
                                                        <div className="border-b border-r border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                                                            <p>{person?.homeAddress?.locationPropertyType}</p>
                                                        </div>
                                                        <div className="border-b border-gray-300 pl-2">
                                                            <p className="font-bold text-xs	">Resident Status</p>
                                                            <p>{person?.residentStatus}&nbsp;</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}

                                            {/*<div className="border-b border-gray-300">
                                        <h2 className=" text-xs bg-blue-900 text-white p-2">VICTIMS-1</h2>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">VICTIMS-1 NAME (LAST, FIRST MIDDLE)</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">DOB / ESTIMATED AGE RANGE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-4">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SEX</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">RACE / ETHNICITY</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PHONE NUMBER</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">EMAIL ADDRESS</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HOME ADDRESS</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">BEEN AT LOCATION SINCE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">DECLARED DATE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PRIOR DIFFICULTIES BETWEEN THE
                                                    PARTIES?</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">VICTIM APPRISED OF AVAILABLE
                                                    REMEDIES?</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">VICTIM RELIGION</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">VICTIM IS OFFICER</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1">
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">LEOKA ACTIVITY TYPE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1">
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">LEOKA ASSIGNMENT TYPE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HISTORY OF DOMESTIC VIOLENCE?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">INFORMATION PROVIDED</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">VICTIM BY ASSOCIATION TYPE</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">VICTIM BY ASSOCIATION RELATION</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">STATEMENT</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">WAS PERSON SEARCHED?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-300">
                                        <h2 className=" text-xs bg-blue-900 text-white p-2">SUSPECTS-1</h2>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SUSPECTS-1 NAME (LAST, FIRST
                                                    MIDDLE)</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">DOB / ESTIMATED AGE RANGE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-4">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SEX</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">RACE / ETHNICITY</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PHONE NUMBER</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">EMAIL ADDRESS</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HOME ADDRESS</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">BEEN AT LOCATION SINCE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HISTORY OF DOMESTIC VIOLENCE?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">OFFENDER RELIGION</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">TICKET NUMBER / FINE AMOUNT</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">VENDOR NUMBER</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">STATEMENT</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">WAS PERSON SEARCHED?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">CLEARED BY SUICIDE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">EVER BEEN A HOUSEHOLD MEMBER WITH
                                                    VICTIM?</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">VICTIM OF CRIMINAL NEGLECT?</p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-300">
                                        <h2 className=" text-xs bg-blue-900 text-white p-2">INVOLVED OTHER-1</h2>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">INVOLVED OTHER-1 (PERSON)</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">DOB / ESTIMATED AGE RANGE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-4">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">SEX</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">RACE / ETHNICITY</p>
                                                <p></p>
                                            </div>
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">PHONE NUMBER</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">EMAIL ADDRESS</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-2 border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">HOME ADDRESS</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">BEEN AT LOCATION SINCE</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="border-r border-b border-gray-300 pl-2">
                                                <p className="font-bold text-xs">STATEMENT</p>
                                                <p></p>
                                            </div>
                                            <div className="border-b border-gray-300 pl-2">
                                                <p className="font-bold  text-xs">WAS PERSON SEARCHED?</p>
                                                <p>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="YES"/>
                                                    <FormControlLabel
                                                        sx={{'& .MuiFormControlLabel-label': {fontSize: 12}}}
                                                        control={<Checkbox
                                                            sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
                                                        label="NO"/>
                                                </p>
                                            </div>
                                        </div>

                                    </div>*/}

                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            
                            

                        </>
                    ))}


                    {/*        <div className="border-b border-gray-300">
                                <h2 className="font-bold text-base bg-blue-900 text-white p-2">INCIDENT INFO</h2>
                            </div>*/}
                </div>
            )
            }

            export default M43OffenseReport;