import React, {useState, useEffect} from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Tooltip,
    Tabs, Tab
} from "@mui/material";
import ArrestDetail from "../../interfaces/Detail/ArrestDetail.interface"
import {RunArrestDetailQuery, RunM43RecordArrestDetailQuery} from "../../services/detail.service";
import {convertUTCtoLocalTimeRange, formatDate, formatSSN, hideSSN} from "../../services/formatDate.service"
import './Detail.css'
import DetailMap from "./_DetailMap";
import { SiteName } from "../../services/config.service";
import { GetStringNameForSite } from "../../interfaces/config.interface";
import M43OffenseReport from "./M43/_M43_OffenseReport";
import M43ArrestReport from "./M43/_M43_ArrestReport";
import M43FieldContactReport from "./M43/_M43_FieldContactReport";
import M43SupplementReport from "./M43/_M43_SupplementReport";
import M43FilesSection from "./M43/_M43_FileAttachments";

interface DetailProps {
    ID: string | null
    department: string | null
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const M43ArrestDetailInfo: React.FC<DetailProps> = ({ID, department}) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any[]>([])
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const loadData = async () =>{        
        if (!DataLoaded){
            setDataLoaded(true)
            let res = await RunM43RecordArrestDetailQuery(ID, department);
            let tData = []
            if(res && res.length > 0){
                for(let i = 0; i < res.length; i++){
                    if(res[i].data){
                        let data = JSON.parse(res[i].data)
                        if(res[i].files)
                            data['files'] = (JSON.parse(res[i].files))
                        tData.push(data)
                    }
                }
            }
            setData(tData)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded){
            setDataLoaded(true)
            loadData()   
        }
    });

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>
                        {children}
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <div className="bg-white p-2 max-w-4xl mx-auto border border-gray-300 mt-10 ">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                    {data.map((rpt, idx)=> {
                        if(rpt.externalReportType === "OFFENSE"){
                            return (
                                <Tab label={`CCN# ${rpt.reportNumber} - Offense/Incident Report`} value={idx} />
                            )
                        } else if(rpt.externalReportType === "ARREST"){
                            return (
                                <Tab label={`ARREST # ${rpt.recordNumber} (${rpt?.externalArrest?.defendant?.lastName}, ${rpt?.externalArrest?.defendant?.firstName})`} value={idx} />
                            )
                        } else if(rpt.externalReportType === "FIELD_CONTACT"){
                            return (
                                <Tab label={`FIELD CONTACT # ${rpt.recordNumber}`} value={idx} />
                            )
                        } else if(rpt.externalReportType === "SUPPLEMENT"){
                            return (
                                <Tab label={`SUPPLEMENT # ${rpt.recordNumber}`} value={idx} />
                            )
                        }
                    })}
                </Tabs>
            </div>


            {data.map((rpt, idx)=> {
                if(rpt.externalReportType === "OFFENSE"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43OffenseReport rpt={rpt} key={idx} />
                            {rpt.files && <M43FilesSection files={rpt.files} />}
                        </TabPanel>
                    )
                } else if(rpt.externalReportType === "ARREST"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43ArrestReport rpt={rpt} key={idx} />
                            {rpt.files && <M43FilesSection files={rpt.files} />}
                        </TabPanel>
                    )
                } else if(rpt.externalReportType === "FIELD_CONTACT"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43FieldContactReport rpt={rpt} key={idx} />
                            {rpt.files && <M43FilesSection files={rpt.files} />}
                        </TabPanel>
                    )
                } else if(rpt.externalReportType === "SUPPLEMENT"){
                    return (
                        <TabPanel value={value} index={idx}>
                            <M43SupplementReport rpt={rpt} key={idx} />
                            {rpt.files && <M43FilesSection files={rpt.files} />}
                        </TabPanel>
                    )
                }
            })}
        </>
    );
}

export default M43ArrestDetailInfo;