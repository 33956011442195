import React, {useState, useEffect} from "react";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Tooltip} from "@mui/material";
import {convertUTCtoLocalTimeRange, formatDate} from "../../../services/formatDate.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import {RunM43RecordRMSDetailQuery} from "../../../services/detail.service";
import DownloadIcon from "@mui/icons-material/Download";
import {Person_GetM43File} from "../../../services/getDar.service";


interface DetailProps {
    files: any[] | null
}

const M43FilesSection: React.FC<DetailProps> = ({files}) => {
    console.log(files)
    const DownloadFile = async (record: any) => {
        console.log(record)
        Person_GetM43File(record.file_mark43Id).then((res) => {
            console.log(res)
            let dL = document.createElement('a');
            dL.href = `data:application/binary;base64,${res}`;
            dL.download = record.file_fileName;
            dL.click();
        });
      
    }
    return (
        <div>
            { (files != null && files?.length > 0) ? <div className="bg-white p-6 max-w-4xl mx-auto shadow-lg border border-gray-300 mt-10 ">
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">FILE ATTACHMENT{files?.length > 1 ? "S":""}</h2>
                    <table className="w-full text-left table-auto min-w-max">
                        <thead>
                            <tr>
                                <th className="p-4 border-b border-slate-300 bg-slate-50">
                                    <p className="block text-sm  leading-none text-slate-500 font-semibold">
                                        Upload Timestamp
                                    </p>
                                </th>
                                <th className="p-4 border-b border-slate-300 bg-slate-50">
                                    <p className="block text-sm leading-none text-slate-500 font-semibold">
                                        File Type
                                    </p>
                                </th>
                                <th className="p-4 border-b border-slate-300 bg-slate-50">
                                    <p className="block text-sm leading-none text-slate-500 font-semibold">
                                        File Name
                                    </p>
                                </th>
                                <th className="p-4 border-b border-slate-300 bg-slate-50">
                                    <p className="block text-sm leading-none text-slate-500 font-semibold">
                                        ACTION
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {files && files.length > 0 && files.map((file: any, index: number) => {
                            return (
                                <tr className="hover:bg-slate-50">
                                    <td className="p-4 border-b border-slate-200">
                                        <p className="block text-sm text-slate-800">
                                            {convertUTCtoLocalTimeRange(file.file_createdDateUtc, null)}
                                        </p>
                                    </td>
                                    <td className="p-4 border-b border-slate-200">
                                        <p className="block text-sm text-slate-800">
                                            {file?.file_fileType}
                                        </p>
                                    </td>
                                    <td className="p-4 border-b border-slate-200">
                                        <p className="block text-sm text-slate-800">
                                            {file?.file_fileName}
                                        </p>
                                    </td>
                                    <td className="p-4 border-b border-slate-200">
                                        <Tooltip title="Download File" placement="top" arrow>
                                            <IconButton size={"small"} style={{ color: "gray" }}  onClick={()=> {DownloadFile(file)}}>
                                                <DownloadIcon style={{ height: 22, width: 22, marginRight: 4 }}/>
                                            </IconButton>
                                        </Tooltip>
                                    </td>
                                </tr>
                                
                            )
                        })}
                        
                       
                        </tbody>
                    </table>
                    
                    
                </div>

            </div> : <></>}
        </div>

    )
}

export default M43FilesSection;

