import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import ECrawlSearchResults from '../../components/tools/ecrawl/_searchResultsECrawl';
import ECrawlReportSearch from '../../components/tools/ecrawl/_ecrawlSearch';
import { GetDarInfo_Query, getDarInfoResponse } from '../../interfaces/getDarInfo_interface';
import { RunDarQuery } from '../../services/getDar.service';
import { Account } from '../../interfaces/auth_interface';
import { handleSearchTime } from '../../services/formatDate.service';
import { getUser } from '../../services/auth.service';

function ECrawl() {

    let [searchResults, setSearchResults] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [hideJail, setHideJail] = useState<boolean>(false)
    const [reloadTriggerCount, setReloadTriggerCount] = useState(0);

    const reloadData = () => {
        setReloadTriggerCount(reloadTriggerCount + 1);
    }

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
            let search_query: GetDarInfo_Query = {
                "QueryType": "ECrawl",
                "Dept": user.Dept,
                "EmpID": user.EmpID,
                "DeptORI": user.DeptORI,
                "DateRange": true,
                "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
                "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
                "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
                "SearchType": andOrType,
                "IndicatorOn": true,
                "TopArrestNumber": 0,
                "FNames": params.FirstName ? params.FirstName.split(',') : [],
                "MNames": params.MI ? params.MI.split(',') : [],
                "LNames": params.LastName ? params.LastName.split(',') : [],
                "DOBs": params.DOB ? params.DOB.split(',') : [],
                "SEX": dropDowns.Sex ? dropDowns.Sex : [],
                "Address": "",
                "Cities": dropDowns.City ? dropDowns.City : [],
                "IncidentCities": dropDowns.IncidentCity ? dropDowns.IncidentCity : [],
                "States": dropDowns.State ? dropDowns.State : [],
                "Zip": "",
                "Statutes": [],
                "Gang": params.CarrierName ? params.CarrierName.split(',') : [],
                "FBIs": params.FBI ? params.FBI.split(',') : [],
                "SBIs": params.SBI ? params.SBI.split(',') : [],
                "DLNos": params.DL ? params.DL.split(',') : [],
                "PlateNos": params.Plate ? params.Plate.split(',') : [],
                "VINs": params.VIN ? params.VIN.split(',') : [],
                "AgencyArrested": [],
                "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
                "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
                "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
                "Latitude": null,
                "Longitude": null,
                "Radius": 0,
                "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
                "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
                "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
                "CaseNumbers": params.DOT ? params.DOT.split(',') : [],
                "SSN": params.SSN ? params.SSN?.split(',') : [],
                "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
                "AgeRangeStart": params.AgeRangeStart,
                "AgeRangeEnd": params.AgeRangeEnd,
                "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
                "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
                "PhoneNumbers": [],
                "SystemCall": params.systemCall ?? false,
            }
            let qRes: getDarInfoResponse = await RunDarQuery(search_query)
            setSearchResults([])
            setSearchResults(qRes.AnyTable)
    
            if (qRes?.AnyTable?.length === 0) {
                setNoData(true)
            }
            else {
                setNoData(false)
            }
        }

    return (
        <div id="ToolsArrest">
            <ECrawlReportSearch SubmitSearch={SubmitSearch} RefreshTrigger={reloadTriggerCount} />
            <ECrawlSearchResults Records={searchResults} RefreshTrigger={reloadData} />
        </div>
    );
}

export default ECrawl;
